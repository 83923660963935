/* eslint-disable import/prefer-default-export */
import React from 'react';

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    width="44"
    height="44"
    fill="#7ebccb"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M83.003,49.504 L100.002,38.008 L100.002,61.000 L83.003,49.504 ZM-0.002,38.008 L16.997,49.504 L-0.002,61.000 L-0.002,38.008 Z" />
      <path d="M8.000,-0.000 L100.000,91.000 L92.000,100.000 L-0.000,9.000 L8.000,-0.000 Z" />
      <path d="M92.000,-0.000 L-0.000,92.000 L9.000,100.000 L100.000,9.000 L92.000,-0.000 Z" />
    </g>
  </svg>
);
