/* eslint-disable import/no-named-as-default */
import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import './header.css';
import Img from '../image';
import HeaderButton from '../header-button/header-button';
import AlexLogo from './logo';
import useEscape from '../../hooks/useEscape';

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    fill="#7ebccb"
    width="28"
    height="28"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M83.003,49.504 L100.002,38.008 L100.002,61.000 L83.003,49.504 ZM-0.002,38.008 L16.997,49.504 L-0.002,61.000 L-0.002,38.008 Z" />
      <path d="M8.000,-0.000 L100.000,91.000 L92.000,100.000 L-0.000,9.000 L8.000,-0.000 Z" />
      <path d="M92.000,-0.000 L-0.000,92.000 L9.000,100.000 L100.000,9.000 L92.000,-0.000 Z" />
    </g>
  </svg>
);

function Header({ siteTitle }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "headerbackground.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `);
  const [isOpen, setIsOpen] = useState(false);

  useEscape(() => {
    setIsOpen(false);
  });

  const navLinks = [
    {
      title: 'Projects',
      link: '/',
    },
    {
      title: 'Gallery',
      link: '/gallery/',
    },
    {
      title: 'About',
      link: '/about/',
    },
    {
      title: 'Contact',
      link: '/contact/',
    },
  ];

  return (
    <header className="ai-header-wrapper">
      <Img
        // customSizes="(max-width: 1000px) 100vh, 5000px"
        customSizes="100vw"
        className="ai-header-image"
        fluid={data.file.childImageSharp.fluid}
        alt="header background image"
        objectPosition="50% 100%"
        clickDisabled
      />
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="ai-header">
              <HeaderButton onClick={() => setIsOpen(!isOpen)} />
              {isOpen && (
                <RemoveScroll>
                  <nav
                    onClick={(e) => {
                      setIsOpen(false);
                    }}
                    className="ai-header-nav ai-link-font"
                  >
                    <div className="ai-header-nav__overlay" />
                    <ul className="ai-header__links">
                      {navLinks.map((headerLink, index) => (
                        <li style={{ animationDelay: `${(2 + index) / 15}s` }}>
                          <Link to={headerLink.link}>
                            <span>{headerLink.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <button
                      type="button"
                      className="ai-button-reset ai-close-nav"
                      style={{ marginTop: '3rem' }}
                    >
                      <CloseIcon />
                    </button>
                  </nav>
                </RemoveScroll>
              )}
              <Link to="/">
                <div className="ai-header__logo-container">
                  <h1 className="ai-header__logo ai-link-font">
                    Alex
                    <br />
                    Illustration
                  </h1>
                  <AlexLogo />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
