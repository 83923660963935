import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="ai-footer">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            Website developed by <a href="https://www.andersuarez.com">Ander Suárez</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
