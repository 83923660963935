import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { RemoveScroll } from 'react-remove-scroll';
import useEscape from '../../hooks/useEscape';

import './image.css';
import { CloseIcon } from '../icons';

const Image = (props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isGalleryVisible, setGalleryVisible] = useState(null);
  useEscape(() => {
    setGalleryVisible(false);
  });
  const { fluid, customSizes } = props;
  const smallImageProps = {
    ...props,
    fluid: {
      ...fluid,
      sizes: customSizes || '(max-width: 991px) 100vh, 425px',
    },
  };
  return (
    <div className="ai-img">
      <Img onContextMenu={(e) => e.preventDefault()} {...smallImageProps} />
      <div
        className={`ai-img__overlay ai-img__overlay--clickable ${
          isTooltipVisible && 'ai-img__overlay--visible'
        }`}
        onClick={(e) => {
          if (!props.clickDisabled) {
            setGalleryVisible(true);
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          setIsTooltipVisible(true);
          setTimeout(() => {
            setIsTooltipVisible(false);
          }, 1500);
        }}
      >
        <div>This photo is Copyright © Alex Illustration. All rights reserved.</div>
      </div>
      {isGalleryVisible && (
        <div
          className="ai-img__gallery"
          onClick={(e) => {
            setGalleryVisible(false);
          }}
        >
          <div className="ai-img__gallery-content">
            <RemoveScroll>
              <div
                className="ai-img__gallery-image-container"
                onClick={(e) => {
                  setGalleryVisible(false);
                }}
              >
                <Img
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onContextMenu={(e) => e.preventDefault()}
                  {...props}
                  className="ai-img__gallery-image"
                  objectFit="contain"
                />
                <div
                  className={`ai-img__overlay ${isTooltipVisible && 'ai-img__overlay--visible'}`}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setIsTooltipVisible(true);
                    setTimeout(() => {
                      setIsTooltipVisible(false);
                    }, 1500);
                  }}
                >
                  <div>This photo is Copyright © Alex Illustration. All rights reserved.</div>
                </div>
              </div>
            </RemoveScroll>
            <button
              className="ai-img__gallery-button"
              type="button"
              onClick={() => {
                setGalleryVisible(false);
              }}
            >
              {' '}
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Image;
