import React from 'react';
import './logo.css';

function Logo() {
  return (
    <svg
      id="alex-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      x="0px"
      y="0px"
      viewBox="0 0 455.6 1210.9"
    >
      <path className="ai-icon-st0" d="M312.8,300.6" />
      <polygon className="ai-icon-st1" points="404.4,167 402.8,453.2 309.8,593.2 309.8,313.2 " />
      <polygon className="ai-icon-st2" points="267.3,21.7 300.3,63.7 300.3,153.7 267.3,105.7 " />
      <polygon className="ai-icon-st2" points="239.3,618.7 239.3,715.7 265.7,756.5 265.7,660.7 " />
      <polygon
        className="ai-icon-st2"
        points="181.3,1034.7 181.3,1130.7 217.3,1185.7 217.3,1086.7 "
      />
      <polygon className="ai-icon-st1" points="445.8,766.2 446.8,919.2 366.8,1038.2 366.8,886.2 " />
      <polygon className="ai-icon-st1" points="72.8,115.2 143.8,227.2 143.8,392.2 72.8,280.2 " />
      <polygon className="ai-icon-st1" points="9.8,576.2 10.2,831.2 116.8,992.2 114.8,733.2 " />
      <polygon
        className="ai-icon-st3"
        points="118.8,367.2 96.8,332.2 78.8,359.2 78.8,676.2 118.8,734.2 "
      />
      <polygon
        className="ai-icon-st3"
        points="151.8,321.2 224.8,210.2 301.8,327.2 320.8,283.2 224.8,137.2 145.8,257.2 "
      />
      <polygon
        className="ai-icon-st3"
        points="330.8,567.2 330.8,782.2 224.8,943.2 122.8,787.2 122.8,859.2 224.8,1016.2 370.8,794.2 370.8,510.2"
      />
    </svg>
  );
}

export default Logo;
