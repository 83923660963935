import React from 'react';
import './header-button.css';
import { useSpring, animated, config } from 'react-spring';

const openedTransformationConfig = {
  top: 'translate(2, 8) rotate(0)',
  center: 'translate(2, 21) rotate(0)',
  bottom: 'translate(2, 34) rotate(0)',
};

const closedTransformationConfig = {
  top: 'translate(2, 8) rotate(0)',
  center: 'translate(2, 21) rotate(0)',
  bottom: 'translate(2, 34) rotate(0)',
};

// const closedTransformationConfig = {
//   top: 'translate(8, 35) rotate(-45)',
//   center: 'translate(7, 7) rotate(45)',
//   bottom: 'translate(8, 35) rotate(-45)',
// }

export function HeaderButton({ isOpened, onClick }) {
  const { top, center, bottom } = useSpring({
    to: isOpened ? closedTransformationConfig : openedTransformationConfig,
    config: config.stiff,
  });
  return (
    <button type="button" onClick={onClick} className="ai-header-button">
      <svg
        width="28"
        height="28"
        viewBox="0 0 44 44"
        fill="#FFF"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.rect width="40" height="2" rx="1" transform={top} />
        <animated.rect width="40" height="2" rx="1" transform={center} />
        <animated.rect width="40" height="2" rx="1" transform={bottom} />
      </svg>
    </button>
  );
}

export default HeaderButton;
